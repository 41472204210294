<template>
  <a-modal
    :title="getTitle"
    :visible="visible"
    :width="wd"
    :centered="true"
    :destroyOnClose="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
    <template>
      <a-form-model-item ref="name" label="用户名" prop="name" v-if="!isEdit">
        <a-input
          v-model="form.name"
          :suffix="getNameSize"
          :maxLength="20"
        
          placeholder="请填写20字以内的用户名"
          @blur="
            () => {
              $refs.name.onFieldBlur();
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item  label="用户名" prop="nametxt" v-else-if="isEdit">
        <span class="text-white">{{form.name}}</span>
      </a-form-model-item>
    </template>
      <!--   :disabled="isEdit" -->
       <template>
        <a-form-model-item
          ref="organizationId"
          label="所属单位"
          prop="organizationId"
          v-if="!isEdit"
        >
          <a-cascader
            :options="organizationListFormat"
            :disabled="isEdit"
            :display-render="displayRender"
            v-model="form.organizationId"
            expand-trigger="hover"
            placeholder="所属单位"
            change-on-select
            @change="selectOrganization"
          />
        </a-form-model-item>
        <a-form-model-item
          
          label="所属单位"
          prop="organizationIdtxt"
          v-else-if="isEdit"
        >
        <span class="text-white block full-width ellipsis" :title="organizationName">{{organizationName}}</span>
        </a-form-model-item>
      </template>
      <a-form-model-item ref="username" label="姓名" prop="username">
        <a-input
          v-model="form.username"
          placeholder="请填写20字以内的姓名"
          :suffix="getUserNameSize"
          :maxLength="20"
        />
      </a-form-model-item>
      <a-form-model-item ref="phone" label="手机号" prop="phone">
        <a-input v-model="form.phone" />
      </a-form-model-item>
     
      <a-form-model-item ref="status" label="是否启用" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio value="1">
            启用
          </a-radio>
          <a-radio value="0">
            停用
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="roleCode" label="关联角色" prop="roleCode">
        <a-select
          mode="multiple"
          class="full-width"
          placeholder="请选择角色"
          v-model="form.roleCode"
        >
          <a-select-option v-for="item in roleList" :key="item.roleCode">
            {{ item.roleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 5 }">
        <a-button type="primary" @click="onSubmit">
          确定
        </a-button>
        <a-button class="m-l-sm" @click="resetForm">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CODE_OK } from '@/request/config_code';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    currentRecord: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    // 手机格式验证
    // 手机格式验证
    const validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号!'));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('手机号格式不正确!'));
        } else {
          callback();
        }
      }
    };

    const validatorName = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入账号!'));
        } else {
          if (value.trim().length < 8 || value.trim().length > 20) {
            callback(new Error('账号长度必须是8～20!'));
          } else {
            callback();
          }
        }
    }
    return {
      wd: '500px',
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      form: {
        name: '',
        username: '',
        phone: '',
        organizationId: [],
        status: '1',
        roleCode: undefined,
      },
      organizationName:'',
      roleList: [],
      rules: {
        name: [
          {
            required: true,
            // message: '请输入账号',
            validator: validatorName,
            trigger: 'blur',
          },
        ],
        username: [
          {
            required: true,
            message: '姓名不能为空',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            validator: validatorPhone,
            trigger: 'blur',
          },
        ],
        organizationId: [
          {
            required: true,
            message: '管辖单位不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    isEdit(nv, ov) {
      if (nv && nv !== ov) {
         this.initFormData(); 
         this.getOrganationList().then(res=>{
           if(res.code === 200) {  
              this.form.organizationId = this.familyTree(
              this.organizationListFormat,
              this.currentRecord.organizationId
            ).reverse();
           }
         })
         
      }
    },
  },
  computed: {
    ...mapState({
      organizationList: (state) => state.collect.organizationList,
    }),
    getTitle() {
      return `${this.isEdit ? '编辑' : '新增'}用户`;
    },
    getNameSize() {
      return `${this.form.name.length || 0}/20`;
    },
    getUserNameSize() {
      return `${this.form.username.length || 0}/20`;
    },
    organizationListFormat() {
      return this.organizationList.map((item) => {
        return item;
      });
    },
  },
  created() {
    this.getOrganationList();
    this._getRoleList();
  },
  methods: {
    ...mapActions(['getOrganationList']),
    initFormData() {
      this.form.name = this.currentRecord.loginName;
      this.form.username = this.currentRecord.userName;
      this.form.phone = this.currentRecord.phoneNum;
      // this.form.organizationId = [this.currentRecord.organizationId];
      /* this.form.organizationId = this.familyTree(
        this.organizationListFormat,
        this.currentRecord.organizationId
      ).reverse(); */
      // console.log(this.form.organizationId);
      this.organizationName = this.currentRecord.organizationName;
      this.form.status = this.currentRecord.userStatus;
      this.form.roleCode = this.currentRecord.roleCode.split(',');
    },
    familyTree(arr1, id) {
      // debugger
      var temp = [];
      var forFn = function(arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.organizationId === id) {
            temp.push(item.organizationId);
            forFn(arr1, item.parentOrganizationId);
            break;
          } else {
            if (item.children) {
              forFn(item.children, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    _getRoleList() {
      this.$api.cameraGroup.getRoleList().then((res) => {
        if (res.code === CODE_OK) {
          this.roleList = res.data;
        }
      });
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectOrganization(value,options) {
      this.form.organizationId = value;
      let currentOrganizationId = value.length > 0 ? value[value.length - 1] : '';
      if(currentOrganizationId === options[0].regionCode) {
        this.form.organizationId = [];
        return;
      }
    },
    handleCancel() {
      this.resetForm();
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let obj = {
            loginName: this.form.name,
            organizationId:
              this.form.organizationId.length > 0
                ? this.form.organizationId[this.form.organizationId.length - 1]
                : '',
            phoneNumber: this.form.phone,
            roleCodes: this.form.roleCode,
            status: this.form.status,
            userName: this.form.username,
          };
          if (this.isEdit) {
            obj.userId = this.currentRecord.userId;
            this.$api.user.updateUser(obj).then((res) => {
              if (res.code === CODE_OK) {
                this.$message.success('更新用户成功！');
                this.resetData();
                this.$emit('sure');
              } else {
                this.$message.error('更新用户失败！');
              }
            });
          } else {
            this.$api.user.addUser(obj).then((res) => {
              if (res.code === CODE_OK) {
                this.$message.success(`新增用户成功！用户密码是${res.data?.password}`,10);
                this.resetData();
                this.$emit('sure');
              } else {
                this.$message.error('新增用户失败！');
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetData() {
      this.$refs.ruleForm.resetFields();
      this.form.roleCode = undefined;
      this.form.name = '';
      this.form.username = '';
      this.form.phone = '';
      // this.form.organizationId = [this.currentRecord.organizationId];
      this.form.organizationId = [];
      this.form.status = '1';
      this.form.roleCode = undefined;
    },
    resetForm() {
      this.resetData();
      this.$emit('close');
    },
  },
};
</script>

<style></style>
