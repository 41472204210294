<template>
  <div>
    <a-modal
      title="权限管理"
      width="40%"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <vue-scroll :ops="$root.scrollOpsY" style="height:300px;">
        <a-tree
          v-model="checkedKeys"
          checkable
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          @expand="onExpand"
          @check="onCheck"
        />
      </vue-scroll>
    </a-modal>
  </div>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
/* const treeData = [
  {
    title: '监控中心',
    key: '0-0',
    children: [
      {
        title: '树状调阅',
        key: '0-0-0',
      },
      {
        title: '正在调取视频统计',
        key: '0-0-1',
      },
      {
        title: '在线率统计',
        key: '0-0-2',
      },
      {
        title: 'GIS地图调阅',
        key: '0-0-3',
        children: [
          { title: '截图', key: '0-0-3-0' },
          { title: '录像', key: '0-0-3-1' },
          { title: '收藏', key: '0-0-3-2' },
          { title: '云台控制', key: '0-0-3-3' },
        ],
      },
      {
        title: '投屏',
        key: '0-0-4',
      },
      {
        title: '综合统计',
        key: '0-0-5',
        children: [
          { title: '拥堵统计', key: '0-0-5-0' },
          { title: '拥堵收费站', key: '0-0-5-1' },
          { title: '事件报警', key: '0-0-5-2' },
          { title: '事件报警', key: '0-0-5-3' },
        ],
      },
    ],
  },
  {
    title: '资源中心',
    key: '0-1',
  },
  {
    title: 'AI中心',
    key: '0-2',
  },
  {
    title: '系统管理',
    key: '0-3',
  },
]; */
export default {
  name: 'AuthDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentRole: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      treeData: [],
    };
  },
  computed: {},
  watch: {
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this.initDefault();
      }
    },
  },
  mounted() {
    this._getAuthority();
  },
  methods: {
    initDefault() {
      this.checkedKeys = this.currentRole.functionInfo[
        this.currentRole.roleCode
      ].filter((item) => !item.includes('1093'));
      // console.log(this.checkedKeys, this.currentRole);
    },
    _getAuthority() {
      let obj = {
        enable: '',
        functionType: '',
        functionName: '',
        sceneType: 1,
      };
      this.$api.authority.getAuthority(obj).then((res) => {
        if (res.code === CODE_OK) {
          this.treeData = this.trackAuthority(res.data);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    trackAuthority(authority, key = '1') {
      let arr = [];
      if (authority.length > 0) {
        // eslint-disable-next-line no-unused-vars
        arr = authority.map((item, index) => {
          // item.key = `${key}-${index}`;
          item.key = item.functionCode;
          item.title = item.functionDesc;
          if (item.childNode && item.childNode.length > 0) {
            item.children = this.trackAuthority(item.childNode, item.key);
          }
          return item;
        });
      }

      return arr;
    },
    onExpand(expandedKeys) {
      console.log('onExpand', expandedKeys);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys;
    },
    /* onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    }, */
    handleOk() {
      this.$emit('close');
    },
    handleCancel() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
