<template>
  <div class="relative scroll">
    <a-tree
      ref="treeDom"
      :tree-data="cameraList"
      :replaceFields="{
        children: 'childList',
        key: 'organizationId',
      }"
      :expandedKeys.sync="expandedKeys"
      @select="onSelect"
      @expand="handleExpand"
    >
      <template slot="customtitle" slot-scope="tags">
        <div class="flex fl-bg" :class="{ current: tags.check }">
          <template>
            <span :title="tags.organizationName">{{ tags.organizationName }}</span>
          </template>
        </div>
      </template>
    </a-tree>
    <div
      v-if="loading"
      class="top-style full-width flex items-center justify-center"
    >
      <a-spin />
    </div>
  </div>
</template>

<script>
import { createGuid } from '@/tools';
import { CODE_OK } from '@/request/config_code';
function formatData(data) {
  let arr = [];
  arr = data.map(item => {
    item.check = false;
    item.scopedSlots = { title: 'customtitle' };
    if(item.childList && item.childList.length > 0) {
      item.childList = formatData(item.childList);
    }
    return item;
  })
  return arr;
}
function clearDataCheck(data,node) {
  for(let i = 0; i < data.length; i++) {
    let item = data[i];
    item.check = false;
    if(item.organizationId === node.organizationId) {
      item.check = true;
    }
    if(item.childList && item.childList.length > 0) {
      clearDataCheck(item.childList,node);
    }
  }
}
function clearDataAllCheck(data) {
  for(let i = 0; i < data.length; i++) {
    let item = data[i];
    item.check = false;
    if(item.childList && item.childList.length > 0) {
      clearDataAllCheck(item.childList);
    }
  }
}
export default {
  data() {
    return {
      cameraList: [],
      loading: false,

      expandedKeys: [],
      organizationId:''
    };
  },
  created() {
    /* let dimensionType = 'province';
    let data = {
      cityCode: '',
      classify: '',
      containGroup: true,
      orderRate: true,
      organizationId: '',
      organizationType: '',
      regionCode: '',
      road: '',
    };
    this.getList(dimensionType, data, (res) => {
      this.loading = false;
      this.cameraList = res.data.data.map((item) => ({
        ...item,
        title: item.name,
        isLeaf: false,
        check: false,
        key: createGuid(),
        type: dimensionType,
        regionCode: dimensionType === 'province' ? item.id : '',
        scopedSlots: { title: 'customtitle' },
      }));
    }); */
    this.getTree();
  },
  methods: {
    clearAllCheck() {
      clearDataAllCheck(this.cameraList);
    },
    onSelect(selectedKeys, e) {
      let selectedKey = e.node.dataRef.organizationId;
      this.organizationId = selectedKey;
      if (!e.node.expanded) {
        this.expandedKeys = [...this.expandedKeys, selectedKey];
      } else {
        this.expandedKeys = this.expandedKeys.filter(
          (item) => item !== selectedKey
        );
      }
      clearDataCheck(this.cameraList,e.node.dataRef);
      this.$emit('click-parent',this.organizationId)
    },
    handleExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    getTree() {
      this.loading = true;
      this.$api.home.getOrgTree().then((res) => {
        if (res.code === CODE_OK) {
          this.loading = false;
          this.cameraList = formatData(res.data);
        }
      });
    },
    onLoadCameraSelectData(treeNode) {
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        }
        let dimensionType = '';
        let data = {
          cityCode: '',
          classify: '',
          containGroup: true,
          orderRate: true,
          organizationId: '',
          organizationType: '',
          regionCode: '',
          road: '',
        };
        const type = treeNode.dataRef.type;
        if (type === 'province') {
          dimensionType = 'organization';
          data.regionCode = treeNode.dataRef.regionCode;
          this.getList(dimensionType, data, (res) => {
            this.loading = false;
            treeNode.dataRef.children = res.data.data.map((item) => ({
              ...item,
              title: item.name,
              isLeaf: false,
              check: false,
              key: createGuid(),
              type: dimensionType,

              organizationId: dimensionType === 'organization' ? item.id : '',
              scopedSlots: { title: 'customtitle' },
            }));

            this.cameraList = [...this.cameraList];
            resolve();
          });
        } else if (type === 'organization') {
          dimensionType = 'road';
          data.organizationId = treeNode.dataRef.organizationId;
          data.organizationType = treeNode.dataRef.organizationType;
          this.getList(dimensionType, data, (res) => {
            this.loading = false;
            treeNode.dataRef.children = res.data.data.map((item) => ({
              ...item,
              title: item.name,
              isLeaf: true,
              check: false,
              key: createGuid(),
              type: dimensionType,

              roadId: dimensionType === 'road' ? item.id : '',
              scopedSlots: { title: 'customtitle' },
            }));

            this.cameraList = [...this.cameraList];
            resolve();
          });
        }

        /* setTimeout(() => {
          treeNode.dataRef.children = [
            { title: 'Child Node', key: `${treeNode.eventKey}-0` },
            { title: 'Child Node', key: `${treeNode.eventKey}-1` },
          ];
          this.treeData = [...this.treeData];
          resolve();
        }, 1000); */
      });
    },
    getList(dimensionType, data, callback) {
      this.loading = true;
      this.$api.home.getCameralList(dimensionType, data).then((res) => {
        if (res.data.code === 200) {
          callback && callback(res);
        }
      });
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow: auto;
}
.current {
  background: #424e65;
  padding: 0 10px;
}
</style>
