<template>
  <a-modal
    title="详情"
    :visible="visible"
    :width="wd"
    :centered="true"
    :destroyOnClose="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <div class="content" :style="styleObj">
      <p class="f16 m-b-sm">
        {{ curRecord.userName ? curRecord.userName : '--' }}
      </p>
      <title-com text="基础信息" />
      <div class="flex items-center flex-wrap p-h-sm bd-b m-b-md">
        <span class="flex-one">
          <span class="text-lightgray m-r-xs">姓名：</span>
          {{ curRecord.userName ? curRecord.userName : '--' }}
        </span>
        <span class="flex-one">
          <span class="text-lightgray m-r-xs">电话：</span>
          {{ curRecord.userName ? curRecord.phoneNum : '--' }}
        </span>
        <span class="flex-one">
          <span class="text-lightgray m-r-xs">所属组织：</span>
          {{ curRecord.userName ? curRecord.organizationName : '--' }}
        </span>
        <span class="flex-one">
          <span class="text-lightgray m-r-xs">组织类型：</span>
          {{ curRecord.userName ? curRecord.organizationTypeDesc : '--' }}
        </span>
        <span class="flex-one">
          <span class="text-lightgray m-r-xs">账号状态：</span>
          {{ curRecord.userStatus === '1' ? '启用' : '停用' }}
        </span>
        <span class="flex-one flex">
          <span class="text-lightgray m-r-xs flex-s">关联角色：</span>
          <span class="ellipsis" :title="curRecord.roleName ? curRecord.roleName : '--' ">{{ curRecord.roleName ? curRecord.roleName : '--' }}</span>
        </span>
      </div>
      <span class="relative p-w-sm text-white tit">关联摄像机组(<span class="text-white">{{cameraGroup.length}}</span>)</span>
      <div class="p-h-sm">
          <div v-for="item in cameraGroup" :key="item.groupId" class="m-b-sm"><span class="m-r-sm tag">摄像机组</span>{{item.groupName}}</div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import TitleCom from '@/components/TitleCom';
import { CODE_OK } from '@/request/config_code';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    curRecord: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      wd: `${window.innerWidth - 100}px`,
      styleObj:{
          height:`${window.innerHeight - 100}px`,
          overflow:'auto'
      },
      cameraGroup: [],
    };
  },
  computed: {},
  watch: {
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this.getDetail();
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    getDetail() {
      let data = {
        pageSize: 10,
        currPage: 1,
        userId: this.curRecord.userId,
      };
      this.$api.user.getDetail(data).then((res) => {
        if (res.code === CODE_OK) {
          this.cameraGroup = res.data;
        } else {
          this.$message.error('获取摄像机组详情失败！');
        }
      });
    },
  },
  components: {
    TitleCom,
  },
};
</script>

<style scoped>
.tit::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 19px;
  background: #96b9e4;
}
.tag{
    background: #409eff;
    color: #fff;
    display: inline-block;
    padding:2px 5px;
    border-radius: 2px;
}
</style>
