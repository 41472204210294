<template>
  <div class="full-width full-height">
    <back class="m-b-sm" v-show="false" />
    <tab
      :tabs="tabs"
      class="m-b"
      ref="tab"
      :initTab="initTab"
      @select="selectHandle"
    />
    <div class="m-b-sm flex full-ht">
      <div class="f-l flex-s full-height">
        <vue-scroll :ops="$root.scrollOpsXY" style="height: calc(100% - 1rem);">
          <auth-tree ref="authTree" @click-parent="clickHandle"></auth-tree>
        </vue-scroll>
      </div>
      <div class="f-r">
        <div class="flex m-b-sm text-lightblue">
          <div class="flex items-center flex-wrap">
            <div class="m-r-sm m-b-sm flex items-center">
              <span class="flex-s m-r-xs">用户名：</span>
              <a-input
                placeholder="请输入用户名"
                class="m-r-sm  search-mx-wd-1"
                v-model="username"
              />
            </div>
            <div class="m-r-sm m-b-sm flex items-center">
              <span class="flex-s m-r-xs">姓名：</span>
              <a-input
                placeholder="请输入姓名"
                class="search-mx-wd-1"
                v-model="name"
              />
            </div>
            <div class="m-r-sm m-b-sm flex items-center">
              <span class="flex-s m-r-xs">用户状态：</span>
              <a-select
                placeholder="用户状态"
                class="m-r-sm search-mx-wd-1"
                v-model="status"
                @change="selectStatus"
              >
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="m-r-sm m-b-sm flex items-center">
              <span class="flex-s m-r-xs">登录时间：</span>
              <a-range-picker
                v-model="dataPicker"
                @change="onDateChange"
                class="search-date-picker"
              />
            </div>
            <div class="m-b-sm">
              <a-button
                type="primary"
                class="m-r-sm btn-search"
                @click="searchFormAllHandle"
              >
                搜索
              </a-button>
              <a-button class="btn-reset" @click="resetFormHandle">
                重置
              </a-button>
            </div>
          </div>
        </div>
        <div class="flex flex-end m-b-sm">
          <span class="span-line m-l-md m-r-md"></span>
          <a-button class="btn-export m-r-sm" @click="addHandle">
            新增
          </a-button>
          <a-button class="btn-export m-r-sm" @click="exportHandle">
            数据导出
          </a-button>
          <a-dropdown>
            <a-button class="btn-export">
              批量处理<a-icon type="caret-down" class="m-l-sm" />
            </a-button>
            <a-menu
              slot="overlay"
              @click="actionHandle"
              v-if="hasPermission(1092171011)"
            >
              <a-menu-item key="1">
                <span class="block text-center">启用</span>
              </a-menu-item>
              <a-menu-item key="2">
                <span class="block text-center">停用</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :components="resibleTableHeader"
          :columns="columns"
          :rowKey="(record, index) => index"
          :data-source="data"
          :pagination="paginationOpt"
          :scroll="scroll"
          :loading="loading"
          bordered
        >
          <template slot="status" slot-scope="text, record">
            <!-- {{ text === '1' ? '启用' : '停用' }} -->
            <a-switch
              title="启用/停用"
              :checked="getCheck(record)"
              @change="(e) => changeCheck(e, record)"
              class="m-r-sm"
            />
          </template>
          <template slot="action" slot-scope="text, record">
            <!-- <a-icon
              type="lock"
              title="修改"
              @click="authHandle(record)"
              class="f20 ve-m"
            /> -->
            <a-icon
              type="edit"
              title="修改"
              @click="modHandle(record)"
              class="f16 ve-m m-r-sm"
            />
            <!-- <a-icon type="copy"  title="详情" class="f16 ve-m" @click="viewDetail(record)"/> -->
            <a-icon
              type="rest"
              title="删除"
              class="f16 ve-m"
              @click="delHandle(record)"
            />
          </template>
        </a-table>
      </div>
    </div>

    <!--    <span class="text-lightblue block m-b-sm">当前在线人数:0</span> -->

    <auth-dialog
      :visible="visibleAuth"
      :currentRole="currentRole"
      @close="closeHandle"
    />
    <add-mod-dialog
      :visible="visibleAddMod"
      :isEdit="editMod"
      :currentRecord="currentRecord"
      @close="closeAddMod"
      @sure="sureAddMod"
    />
    <detail-dialog
      :visible="visibleDet"
      :curRecord="curRecord"
      @close="closeDet"
    />
  </div>
</template>

<script>
import Back from '@/components/Back';
import AuthDialog from './components/AuthDialog';
import Tab from '@/components/Tab';
import tabMixin from '@/components/mixins/tabs';
import { handleExportFile } from '@/tools';
import moment from 'moment';
import AuthTree from './components/AuthTree.vue';
import AddModDialog from './components/AddModDialog.vue';
import DetailDialog from './components/DetailDialog.vue';
import { CODE_OK } from '@/request/config_code';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'UserManage',
  mixins: [tabMixin,ResizableTable],
  data() {
    return {
      loading: false,
      scroll: { y: window.innerHeight - 420 ,x:'100%'},
      username: '',
      name: '',
      status: undefined,
      dataPicker: null,
      organizationId: '',
      statusList: [
        {
          id: 0,
          value: 1,
          name: '启用',
        },
        {
          id: 1,
          value: 0,
          name: '停用',
        },
      ],
      selectedRowKeys: [],
      selectedRowAll: [],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;

          this.selectedRowKeys = [];
          this._getUserList();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.selectedRowKeys = []; //fixsxy
          this._getUserList();
        },
      },
      columns: [
        {
          title: '序号',
          dataIndex:'userIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '用户名',
          dataIndex: 'loginName',
          key: 'loginName',
          width:90,
          ellipsis: true,
        },
        {
          title: '姓名',
          dataIndex: 'userName',
          key: 'userName',
          width:120,
          ellipsis: true,
        },
        {
          title: '电话',
          dataIndex: 'phoneNum',
          key: 'phoneNum',
          ellipsis: true,
          width: 100,
        },
        {
          title: '所属单位',
          dataIndex: 'organizationName',
          key: 'organizationName',
          width:120,
          ellipsis: true,
        },
        {
          title: '当前角色',
          dataIndex: 'roleName',
          key: 'roleName',
          width: 120,
          ellipsis: true,
        },
        /*  {
          title: '创建时间',
          dataIndex: 'createDate',
          key: 'createDate',
          width: 120,
          ellipsis: true,
        },
        {
          title: '创建人',
          dataIndex: 'createUser',
          key: 'createUser',
        }, */
        {
          title: '最后登录时间',
          dataIndex: 'lastLoginTime',
          key: 'lastLoginTime',
          width:100,
          ellipsis:true
        },
        {
          title: '状态',
          dataIndex: 'userStatus',
          key: 'userStatus',
          width:70,
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          className:'column-center',
          width:80,
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      visibleAuth: false,
      currentRole: {},
      visibleAddMod: false, //新增弹窗显隐
      editMod: false, //新增编辑与否
      currentRecord: {},
      visibleDet: false,
      curRecord: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this._getUserList();
  },
  methods: {
    addHandle() {
      this.visibleAddMod = true;
      this.editMod = false;
    },
    modHandle(record) {
      this.visibleAddMod = true;
      this.editMod = true;
      this.currentRecord = record;
    },
    closeAddMod() {
      //关闭新增弹窗触发
      this.visibleAddMod = false;
      this.editMod = false;
      this.currentRecord = {};
    },

    sureAddMod() {
      //确定新增弹窗触发
      this.editMod ? this.searchFormHandle() : this.resetFormHandle();
      this.closeAddMod();
    },
    //删除用户
    delHandle(record) {
      let _this = this;
      this.$confirm({
        title: '删除',
        content: '确定删除该用户吗?',
        okText: '确定',
        cancelText: '取消',
        centered: true,
        onOk() {
          let params = {
            userIds: [record.userId],
          };
          _this.$api.user.deleteUser(params).then((res) => {
            if (res.code == CODE_OK) {
              _this.$message.success('删除成功');
              _this.resetFormHandle();
            }
          });
        },
        onCancel() {},
      });
    },
    closeDet() {
      this.visibleDet = false;
    },
    viewDetail(record) {
      this.curRecord = record;
      this.visibleDet = true;
    },
    clickHandle(organizationId) {
      this.organizationId = organizationId;
      this.searchFormHandle();
    },
    selectHandle(index) {
      this.selectCurrent(index);
    },
    _getUserList() {
      let data = {
        pageSize: this.paginationOpt.pageSize,
        currPage: this.paginationOpt.current,
        loginName: this.username,
        organizationId: this.organizationId,
        userName: this.name,
        userStatus: this.status,
        startDate:
          this.dataPicker && this.dataPicker.length > 0
            ? moment(this.dataPicker[0]).format('YYYY-MM-DD')
            : '',
        endDate:
          this.dataPicker && this.dataPicker.length > 0
            ? moment(this.dataPicker[1]).format('YYYY-MM-DD')
            : '',
      };
      this.loading = true;
      this.$api.user.getUserList(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.data = res.data;
          this.paginationOpt.total = res.total;
        }
      });
    },
    exportHandle() {
      if (!this.hasPermission(1092171012)) {
        this.permissionWarning();
        return;
      }

      let data = {
        pageSize: this.paginationOpt.pageSize,
        currPage: this.paginationOpt.current,
        loginName: this.username,
        organizationId: '',
        userName: this.name,
        userStatus: this.status,
        startDate: this.dataPicker
          ? moment(this.dataPicker[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        endDate: this.dataPicker
          ? moment(this.dataPicker[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
      };
      this.$api.user.downloadUser(data).then((res) => {
        handleExportFile(res, '用户列表.xls');
      });
    },
    selectStatus(e) {
      this.status = e;
    },
    onDateChange(data) {
      console.log('clear', data);
      this.dataPicker = data;
    },
    searchFormHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getUserList();
    },
    //搜索按钮全量测试
    searchFormAllHandle() {
      this.organizationId = '';
      //清空选中
      this.$refs['authTree'] && this.$refs['authTree'].clearAllCheck();
      this.searchFormHandle();
    },
    resetFormHandle() {
      this.paginationOpt.current = 1;
      this.username = '';
      this.name = '';
      this.dataPicker = undefined;
      this.status = undefined;
      this.organizationId = '';
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      //清空选中
      this.$refs['authTree'] && this.$refs['authTree'].clearAllCheck();
      this._getUserList();
    },
    actionHandle({ key }) {
      if (!this.hasPermission(1092171011)) {
        this.permissionWarning();
        return;
      }

      console.error(key);
      if (this.selectedRowAll.length <= 0) {
        this.$message.error('请选择数据');
        return;
      }
      let data = {
        userIds: this.selectedRowAll.map((item) => item.userId),
      };

      switch (key) {
        case '1':
          data.status = 1;
          break;
        case '2':
          data.status = 0;
          break;
      }
      this._modifyUserStatus(data);
    },
    onSelectChange(selectedRowKeys, selectedRowAll) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowAll = selectedRowAll;
    },
    changeCheck(e, records) {
      if (!this.hasPermission(1092171011)) {
        this.permissionWarning();
        return;
      }

      records.userStatus = e ? '1' : '0';
      let data = {
        status: e ? 1 : 0,
        userIds: [records.userId],
      };
      this._modifyUserStatus(data);
    },
    _modifyUserStatus(data) {
      this.$api.user.batchUserStatus(data).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改状态成功');
          this._getUserList();
          this.selectedRowAll = [];
          this.selectedRowKeys = [];
        }
      });
    },
    getCheck(item) {
      return item.userStatus === '1' ? true : false;
    },
    authHandle(records) {
      if (!this.hasPermission(1092171010)) {
        this.permissionWarning();
        return;
      }

      // console.error(records);
      this.$api.aiCenterS
        .getChoseListByAllRole({ roleCode: records.roleCode })
        .then((res) => {
          if (res.code === 200) {
            records.functionInfo = res.data;
            this.currentRole = records;
            this.visibleAuth = true;
          }
        });
    },
    closeHandle() {
      this.visibleAuth = false;
    },
  },
  components: {
    Back,
    AuthDialog,
    Tab,
    AuthTree,
    AddModDialog,
    DetailDialog,
  },
};
</script>
<style scoped>
.wd20 {
  width: 14%;
}
.wd25 {
  width: 20%;
}
.f-l {
  width: 440px;
}
.f-r {
  width: calc(100% - 440px);
}
.full-ht {
  height: calc(100% - 60px);
}
</style>
